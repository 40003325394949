/* ========================================================================
 * .noConflict()
 * Enclosed everything within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

	$.fn.isOnScreen = function(){

	  var win = $(window);

	  var viewport = {
	    top : win.scrollTop(),
	    left : win.scrollLeft()
	  };

	  viewport.right = viewport.left + win.width();
	  viewport.bottom = viewport.top + win.height();

	  var bounds = this.offset();
	      bounds.right = bounds.left + this.outerWidth();
	      bounds.bottom = bounds.top + this.outerHeight();

	  return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));

	};

	  if(lang_code_html == 'nl'){
	    var btw_text = 'btw';
    }else{
      var btw_text = 'vat';
    }


    $select_city = $('select[name="from"]').selectize({
      onInitialize: function () {
        var s = this;
        this.revertSettings.$children.each(function () {
          $.extend(s.options[this.value], $(this).data());
        });
      }, onChange: function (value) {
        var option = this.options[value];
        $('select#pickup_country').val(option.valueShort);
      }
    });

    //$select_city = $('select[name="from"]').selectize();
    // $select_city = $('select[name="to"]').selectize();

    $select_city = $('select[name="to"]').selectize({
        onInitialize: function () {
          var s = this;
          this.revertSettings.$children.each(function () {
            $.extend(s.options[this.value], $(this).data());
          });
        }, onChange: function (value) {
            var option = this.options[value];
            $('select#shipping_country').val(option.valueShort);

            if($('#billing_country option:selected').val() == 'NL'){
              $('#billing_country').val(option.valueShort);
              console.log('New billingCountry = ' + option.valueShort);
            }
          }
      });

  $('select[name="to"]').trigger( "click" );

    $('.typeSender .option').click(function(){
        $('.typeSender .option').removeClass('active');
        $(this).addClass("active");
        $('input[name="typeSender"]').val($(this).attr('data-name'));

        if($('.calculator') && $(this).attr('data-name') == 'b2c') {
            $('.calculator').addClass('part');


        } else {
            $('.calculator').removeClass('part');
        }
    });


      function checkIfParticular(){
        if($('.calculator').hasClass('part')){
          $('.input-only-business').hide();
          $('[name="billing_vat"]').val('nvt');
          $('[name="billing_kvk"]').val('nvt');
          $('[name="shipping_vat"]').val('nvt');
          $('[name="shipping_company"]').val('nvt');
          $('[name="billing_company"]').val('nvt');
          $('[name="pickup_company"]').val('nvt');

          $('[name="shipping_vat"]').removeAttr('required');
          $('[name="billing_company"]').removeAttr('required');
          $('[name="pickup_company"]').removeAttr('required');
          $('[name="shipping_company"]').removeAttr('required');

        }else{
          $('.input-only-business').show();
          $('[name="shipping_vat"]').attr('required', 'required');
          $('[name="billing_company"]').attr('required', 'required');
          $('[name="shipping_company"]').attr('required', 'required');
          $('[name="pickup_company"]').attr('required', 'required');
        }
      }

    checkIfParticular();


	/// load foundation
	$(document).foundation();

    /// faq
    $('.faq-item-single-title').click(function(){
          $(this).toggleClass('active');
          $(this).parent().toggleClass('active');
          $(this).parent().find('.faq-item-single-content').slideToggle();
       });

	$(document).ready(function(){
        $('.testimonials-slider').slick({
            dots: false,
            arrows: false,
            infinite: false,
            speed: 700,
            slidesToShow: 5,
            autoplaySpeed: 2000,
            autoplay: true,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                }
            ]
        });


    });

    if ($(window).width() < 550) {
        $('.location-slider').slick({
            dots: false,
            infinite: true,
            speed: 700,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplaySpeed: 2000,
            autoplay: true,
            arrows: false,
        });
    }

    //// calculator homepage
    $(document).on('change', 'select[name="package_type"]', function(){
        var val = $(this).val();
        if(val == 'pallet') {
            $('.extra_pallet').show();
        } else {
            // $('.extra_pallet').hide();
        }
    });

    if($('.extra_pallet').length){
      $('.extra_pallet').show();
    }

    $(".homepage_box").validate();
    $(".checkout_wrap").validate();
    $(".offer_form form").validate();

    $.validator.setDefaults({
        ignore: []
    });

    /// resultaat page
    $(document).on('click', '.packages .package .title', function(){
        $(this).parent().toggleClass('show');
    });

    var insurance = 0;
    $(document).on('change', 'input[name="insurance"]', function(){
        var vat = parseFloat($('.checkout_wrap').attr('data-vat'));

        if ($('input[name="insurance"]').is(":checked")) {
            $('.insurance_value').addClass('show');
        } else {
            $('input[name="insurance_price"]').val(0);
            $('input[name="insurance_value"]').val(0);
            $('.insurance_sidebar').hide();
            $('.insurance_sidebar .value').html('');
            $('.insurance_value').removeClass('show');

            total_price = parseFloat($('input[name="total_price"]').val());
            total_price = (total_price);
            btw_price = (total_price / 100) * vat;
            total_price_inc = Number(total_price) + Number(btw_price);

            $('.price_box .value span').html('&euro;<span>'+ parseFloat(total_price).toFixed(2) +'</span>');
            $('.price_boxB2C .value span').html('&euro;<span>'+ parseFloat(total_price_inc).toFixed(2) +'</span>');
        }
    });

    $(document).on('keyup', 'input[name="insurance_value"]', function(){
        var vat = parseFloat($('.checkout_wrap').attr('data-vat'));
        var value = $(this).val();
        insurance = value * 0.015;

        if(insurance > 15) {
            $('input[name="insurance_price"]').val(insurance);
        } else {
            insurance = 15;
            $('input[name="insurance_price"]').val(15);
        }

        if(insurance > 0) {
            $('.insurance_sidebar').show();
            $('.insurance_sidebar .value').html('&euro;' + insurance.toFixed(2));

            total_price = parseFloat($('input[name="total_price"]').val());
            btw_price = (total_price / 100) * vat;
            total_price = (total_price) + parseFloat(insurance);
            total_price_inc = Number(total_price) + Number(btw_price);

            $('.price_box .value span').html('&euro;<span>'+ parseFloat(total_price).toFixed(2) +'</span>');
            $('.price_boxB2C .value span').html('&euro;<span>'+ parseFloat(total_price_inc).toFixed(2) +'</span>');

        } else {
            $('.insurance_sidebar').hide();
            $('.insurance_sidebar .value').html('');

            total_price = parseFloat($('input[name="total_price"]').val());
            total_price = (total_price);
            btw_price = (total_price / 100) * vat;
            total_price_inc = Number(total_price) + Number(btw_price);

            $('.price_box .value span').html('&euro;<span>'+ parseFloat(total_price).toFixed(2) +'</span>');
            $('.price_boxB2C .value span').html('&euro;<span>'+ parseFloat(total_price_inc).toFixed(2) +'</span>');
        }

    });

    $(document).on('click', '.remove_package', function(){
        $(this).parent().parent().parent().parent().remove();

        var packages = $('.packages .package').length;

        if(packages > 1) {
            $('.remove_package').show();
        } else {
            $('.remove_package').hide();
        }

        get_methods();
    });

    $('.extra_package').click(function(){
        var inputs_html = $('.package').last().find('.inputs').html();
        var item_number = (parseInt($('.package').last().attr('data-item')) + 1);
        var packages = ($('.packages .package').length + 1);
        var insert_html = '';

        insert_html += '<div class="package show" data-item="'+ item_number +'"><div class="title"><span>Pakket '+ item_number +'</span><div class="open"><i class="fas fa-chevron-right"></i></div></div><div class="inputs">';
        insert_html += inputs_html;
        insert_html += '</div></div>';

        $(insert_html).insertAfter( $('.packages .package[data-item="'+ (item_number - 1) +'"]') );

        $('.packages .package[data-item="'+ item_number +'"] input').each(function(){
            $(this).val('');
        });

      $('.packages .package[data-item="'+ item_number +'"] textarea').each(function(){
        $(this).val('');
        $(this).attr('value', '');
        $(this).html('');
      });

        if(packages > 1) {
            $('.remove_package').show();
        } else {
            $('.remove_package').hide();
        }

        get_methods();
    });

    /// calc prices
    if($('.method_box').length > 0) {
        var url = $('.calculator').attr('data-url');

        $(document).on('change', '.step1 input, .step1 select, .location-bar select, .package textarea', function(){
            if(!($(this).attr('name') == 'insurance' || $(this).attr('name') == 'insurance_value')) {
                get_methods();
            }

            if($(this).attr('name') === 'to'){

            }
        });

        function postal_check(postal) {
            var to_location = $('select[name="to"]').val();
            var method_name = $('input[name="method_name"]').val();
            var vat = parseFloat($('.checkout_wrap').attr('data-vat'));

            $.ajax({
                type: "POST",
                url: url + '/api/postal.php',
                data: {
                  postal: postal,
                  location: to_location,
                  method: method_name
                },
                success: function(val){
                    if(val != 'false') {
                        var current_price = $('input[name="total_price"]').val();

                        total_price = +current_price + val;
                        btw_price = (total_price / 100) * vat;
                        total_price_inc = Number(total_price) + Number(btw_price);

                        $('.price_box .value span').html('&euro;<span>'+ parseFloat(total_price).toFixed(2) +'</span>');
                        $('.price_box .vat span').html('&euro;'+ parseFloat(btw_price).toFixed(2));

                        $('.price_boxB2C .value span').html('&euro;<span>'+ parseFloat(total_price_inc).toFixed(2) +'</span>');
                        $('.price_boxB2C .vat span').html('&euro;'+ parseFloat(btw_price).toFixed(2));

                        $('input[name="total_price"]').val(total_price);
                        $('.extra_fee').removeClass('hide');
                    } else {
                        $('.extra_fee').addClass('hide');
                    }
                }
            });
        }

        $('input[name="shipping_postcode"]').change(function(){
            postal_check($(this).val());
        });

        function get_methods() {

            $('.mehtod_loader').show();
            $('.method_box .method').remove();
            $('.zending_loader').hide();

            var from_location = $('select[name="from"]').val();
            var to_location = $('select[name="to"]').val();
            var billing_location = $('select[name="billing_country"] option:selected').val();
            var lang = $('input[name="lang"]').val();
            var packs = [];

            $('.packages .package').each(function(){
              var i = $(this).attr('data-item');
              var data = {};
              data.width = $('.packages .package[data-item="'+ i +'"] input[name="width"]').val();
              data.height = $('.packages .package[data-item="'+ i +'"] input[name="height"]').val();
              data.length = $('.packages .package[data-item="'+ i +'"] input[name="length"]').val();
              data.weight = $('.packages .package[data-item="'+ i +'"] input[name="weight"]').val();
              data.description = $('.packages .package[data-item="'+ i +'"] textarea[name="description"]').val();
              data.package_type = $('.packages .package[data-item="'+ i +'"] select[name="package_type"]').val();


              console.log('package desc ' + data.description);

              if ($('input[name="stackable"]').is(":checked")) {
                  data.stackable = 'false';
              } else {
                  data.stackable = 'true';
              }

              packs.push(data);
              i++;
            });

            $('.from .value').html(from_location);
            $('.to .value').html(to_location);

            pack_html = '';
            $.each(packs, function(k, v) {
                if(v.width > 0) {
                    pack_html += v.length + 'x' + v.width + 'x' + v.height + 'cm - ' + v.weight + 'kg<br/>';
                }
            });

            $('input[name="from"]').val(from_location);
            $('input[name="to"]').val(to_location);
            $('input[name="package"]').val(pack_html);

            if(pack_html.length > 0) {
                $('.sidebar_packages .value').html(pack_html);
                $('.sidebar_packages').show();
            } else {
                $('.sidebar_packages').hide();
            }


            var billing_eu = false;
            var eu_array = ['AT', 'BE', 'BG', 'HR', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE'];


            if(eu_array.includes($('#shipping_country').val())){
              billing_eu = true;
              console.log($('#shipping_country').val() + ' is in EU');
            }

            $.ajax({
                type: "POST",
                url: url + '/api/api.php',
                data: {
                  packs: packs,
                  from: from_location,
                  to: to_location,
                  billing: billing_location,
                  lang: lang,
                  type: 'normal'
                },
                success: function(val){
                    var data = jQuery.parseJSON(val);

                    if(!(val == 'false' || data.return == 'false')) {
                        $('.method_box .method').remove();
                        var insert_html = '';
                        var insurance_price = $('input[name="insurance_price"]').val();
                        var vat = 0;

                        if(billing_eu){
                           vat = 21;
                        }

                        $('.checkout_wrap').attr('data-europe', data.info.europa);
                        $('input[name="europe"]').val(data.info.europa);

                        console.error(data.info.europa);

                        if(data.info.europa === 'false') {
                            $('input[name="invoice"]').prop('required', true);
                            $('.upload_invoice sup').show();

                            $('.shipping_vat').show();
                            $('.shipping_vat input').prop('required', true);

                            $('.b2b').css('display', 'block');
                            $('.b2c').css('display', 'none');
                        } else {
                            $('input[name="invoice"]').prop('required', false);
                            $('.upload_invoice sup').hide();

                            $('.shipping_vat').hide();
                            $('.shipping_vat input').prop('required', false);

                            $(".b2b").removeAttr("style");
                            $(".b2c").removeAttr("style");
                        }

                        var first = 'true';
                        $.each(data.methodes, function(k, v) {
                           if(v.price > 0) {
                               insert_html += '<div data-method-marge="'+ v.marge +'" data-price="'+ v.price +'" data-method="'+ v.name +'" class="method';

                               if(first == 'true') {
                                 insert_html += ' selected';
                               }

                               total_price = v.price + parseFloat(insurance_price);
                               btw_price = (v.price / 100) * vat;
                               total_price_inc = Number(total_price) + Number(btw_price);

                               insert_html += '"><div class="value image"><img src=" '+ theme_url +'/source/images/'+ v.name.toLowerCase().replace(/ /g,"_") +'.png" alt=""></div>';
                               insert_html += '<div class="value name">'+ v.name +'</div>';
                               insert_html += '<div class="value pickup">'+ v.pickup_time +'</div>';
                               insert_html += '<div class="value time">'+ v.transit + '</div>';
                               insert_html += '<div class="value price b2b">&euro;<span>'+ v.price +'</span> <sup>excl. '+ btw_text +'</span></div>';
                               insert_html += '<div class="value priceB2C b2c">&euro;<span>'+ parseFloat(total_price_inc).toFixed(2) +'</span> <sup>incl. '+ btw_text +'</span></div></div></div>';

                               if(first == 'true') {
                                   $('input[name="total_price"]').val(v.price);
                                    $('input[name="marge"]').val(v.marge);
                                   $('input[name="packages"]').val(JSON.stringify(packs));
                                   $('.price_box').show();
                                   $('.price_boxB2C').show();

                                   $('input[name="method_name"]').val(v.name);
                                   $('.price_box .value span').html('&euro;<span>'+ parseFloat(total_price).toFixed(2) +'</span>');
                                   $('.price_box .vat span').html('&euro;'+ parseFloat(btw_price).toFixed(2));

                                   $('.price_boxB2C .value span').html('&euro;<span>'+ parseFloat(total_price_inc).toFixed(2) +'</span>');
                                   $('.price_boxB2C .vat span').html('&euro;'+ parseFloat(btw_price).toFixed(2));
                               }

                               first = 'false';
                           }
                        });

                        console.log('data europe = ' + data.info.europa);



                        if(insert_html.length == 0) {
                            $('.mehtod_loader').hide();
                            $('.offerte_message').show();
                            $('.insurance').hide();
                            $('.go-step2').hide();
                        } else {
                            $('.mehtod_loader').hide();
                            $('.offerte_message').hide();
                            $('.insurance').show();
                            $('.method_options').prepend(insert_html);
                            $('.go-step2').show();
                        }

                      if(data.info.europa === 'false') {
                        $('.value.priceB2C.b2c').css('display', 'none');
                        $('.value.price.b2b').css('display', 'block');

                      } else {
                        $('.value.priceB2C.b2c').removeAttr("style");
                        $('.value.price.b2b').removeAttr("style");
                      }

                    }
                }
            });

        } get_methods();
    }

    /// switch method
    $(document).on('click', '.method_options .method', function(){
        $('.method_options .method').removeClass('selected');
        $(this).addClass('selected');

        var insurance_price = $('input[name="insurance_price"]').val();
        var vat = parseFloat($('.checkout_wrap').attr('data-vat'));
        var price = $(this).attr('data-price');
        var name = $(this).attr('data-method');
        var marge = $(this).attr('data-method-marge');

        $('.margeval').val(marge);
        $('.margeval').attr('value', marge);

        $('input[name="total_price"]').val(price);
        $('.price_box').show();
        $('.price_boxB2C').show();

        total_price = price + parseFloat(insurance_price);
        btw_price = (price / 100) * vat;
        total_price_inc = Number(total_price) + Number(btw_price);

        $('input[name="method_name"]').val(name);
        $('.price_box .value span').html('&euro;<span>'+ parseFloat(total_price).toFixed(2) +'</span>');
        $('.price_box .vat span').html('&euro;'+ parseFloat(btw_price).toFixed(2));

        $('.price_boxB2C .value span').html('&euro;<span>'+ parseFloat(total_price_inc).toFixed(2) +'</span>');
        $('.price_boxB2C .vat span').html('&euro;'+ parseFloat(btw_price).toFixed(2));

    });

    /// got step 2
    $('.go-step1, .gostep1').click(function(){
        $('.step').hide();
        $('.step1').show();
        $('.location-bar .step1').show();
        $('.steps').hide();
        $('.step2').hide();
        $('.steps .timeline').removeClass('active');
        $('.steps .time_1').addClass('active');

        $("html, body").animate({ scrollTop: "0px" });

    });


    $('.go-step2, .gostep2').click(function(){
        $(".checkout_wrap").validate();

        if($('.method.selected').length > 0) {
            $('.step1').hide();
            $('.location-bar .step1').hide();
            $('.steps').show();
            $('.step').hide();
            $('.step2').show();
            $('.steps .timeline').removeClass('active');
            $('.steps .time_2').addClass('active');
            $("html, body").animate({ scrollTop: "0px" });
        } else {
            // error message
        }
    });

    $('.go-step3, .gostep3').click(function(){
        var step3_status = 'true';
        $('.step2 input').each(function(){

          $('.ophaal_gegevens').show();
          $('.bezorg_gegevens').show();

            // Fill boxes to  the right with this information
            var name = $(this).attr('name');
            if($('.' + name + '_from').length){
                $('.' + name + '_from').html($(this).val());
            }

            if($(this).prop('required') && $(this).val().length == 0){
                step3_status = 'false';
                $(this).addClass('error');
            } else {
                $(this).removeClass('error');
            }
        });

        if(step3_status == 'true') {
            $(".checkout_wrap").validate();

            $('.step').hide();
            $('.step3').show();
            $('.steps .timeline').removeClass('active');
            $('.steps .time_3').addClass('active');
        }

        $("html, body").animate({ scrollTop: "0px" });
    });

    $('.go-step4, .gostep4').click(function(){
        var step4_status = 'true';
        $('.step3 input').each(function(){
            if($(this).prop('required') && $(this).val().length == 0){
                step4_status = 'false';
                $(this).addClass('error');
            } else {
                $(this).removeClass('error');
            }
        });

        if(step4_status == 'true') {
            $('.step').hide();
            $('.step4').show();
            $('.steps .timeline').removeClass('active');
            $('.steps .time_4').addClass('active');
        }

        $("html, body").animate({ scrollTop: "0px" });
    });

    $('.go-step1, .go-step2, .go-step3, .go-step4').click(function(){
      checkIfParticular();
    });

    $('.offerte').click(function(){
        $('.offer_form').show();
    });

    $('.offer_form .close').click(function(){
        $('.offer_form').hide();
    });

    $('input[name="payment_method"]').change(function(){
        $('.wc_payment_method').removeClass('active');
        $(this).parent().addClass('active');
    });

    $( "input[name='pickup_date']" ).datepicker({
        minDate: '0',
        dateFormat: 'dd-mm-yy'
    });

    $('input[name="copy"]').change(function(){
        var company = '';
        var contact = '';
        var address_1 = '';
        var address_2 = '';
        var address_3 = '';
        var postcode = '';
        var city = '';
        var country = '';
        var phone = '';
        var email = '';

        if($(this).val() == 'ophaal') {
            company = $('input[name="pickup_company"]').val();
            contact = $('input[name="pickup_contact"]').val();
            address_1 = $('input[name="pickup_address_1"]').val();
            address_2 = $('input[name="pickup_address_2"]').val();
            address_3 = $('input[name="pickup_address_3"]').val();
            postcode = $('input[name="pickup_postcode"]').val();
            city = $('input[name="pickup_city"]').val();
            country = $('select[name="pickup_country"]').val();
            phone = $('input[name="pickup_phone"]').val();
            email = $('input[name="pickup_mail"]').val();
        } else if($(this).val() == 'bezorg') {
            company = $('input[name="shipping_company"]').val();
            contact = $('input[name="shipping_first_name"]').val();
            address_1 = $('input[name="shipping_address_1"]').val();
            address_2 = $('input[name="shipping_address_2"]').val();
            address_3 = $('input[name="shipping_address_3"]').val();
            postcode = $('input[name="shipping_postcode"]').val();
            city = $('input[name="shipping_city"]').val();
            country = $('select[name="shipping_country"]').val();
            phone = $('input[name="shipping_phone"]').val();
            email = $('input[name="shipping_mail"]').val();
        }

        $('input[name="billing_first_name"]').val(contact);
        $('input[name="billing_company"]').val(company);
        $('input[name="billing_address_1"]').val(address_1);
        $('input[name="billing_address_2"]').val(address_2);
        $('input[name="billing_address_3"]').val(address_3);
        $('input[name="billing_postcode"]').val(postcode);
        $('input[name="billing_city"]').val(city);
        $('select[name="billing_country"]').val(country);
        $('input[name="billing_phone"]').val(phone);
        $('input[name="billing_email"]').val(email);
    });

    $('select[name="billing_country"], select[name="to"]').change(function() {
      // console.log($(this).val());

       // if($(this).val() !== 'NL') {
       //     $('.checkout_wrap').attr('data-vat', '0');
       // } else {
       //     $('.checkout_wrap').attr('data-vat', '21');
       // }

      // get_methods();

      // if($('.checkout_wrap').attr('data-europe') !== 'false') {
      //   $('.checkout_wrap').attr('data-vat', '21');
      // } else {
      //   $('.checkout_wrap').attr('data-vat', '0');
      // }

      if($('body').hasClass('page-template-template-resultaat')){
        get_methods();
      }


    });

    $('input[name="copy_invoice"]').change(function(){
        var company = '';
        var contact = '';
        var address_1 = '';
        var address_2 = '';
        var address_3 = '';
        var postcode = '';
        var city = '';
        var country = '';
        var phone = '';
        var email = '';

        contact = $('input[name="billing_first_name"]').val();
        company = $('input[name="billing_company"]').val();
        address_1 = $('input[name="billing_address_1"]').val();
        address_2 = $('input[name="billing_address_2"]').val();
        address_3 = $('input[name="billing_address_3"]').val();
        postcode = $('input[name="billing_postcode"]').val();
        city = $('input[name="billing_city"]').val();
        country = $('select[name="billing_country"]').val();
        phone = $('input[name="billing_phone"]').val();
        email = $('input[name="billing_email"]').val();

        if($(this).val() == 'ophaal') {
            $('input[name="pickup_company"]').val(company);
            $('input[name="pickup_contact"]').val(contact);
            $('input[name="pickup_address_1"]').val(address_1);
            $('input[name="pickup_address_2"]').val(address_2);
            $('input[name="pickup_address_3"]').val(address_3);
            $('input[name="pickup_postcode"]').val(postcode);
            $('input[name="pickup_city"]').val(city);
            $('select[name="pickup_country"]').val(country);
            $('input[name="pickup_phone"]').val(phone);
            $('input[name="pickup_mail"]').val(email);
        } else if($(this).val() == 'bezorg') {
            $('input[name="shipping_company"]').val(company);
            $('input[name="shipping_first_name"]').val(contact);
            $('input[name="shipping_address_1"]').val(address_1);
            $('input[name="shipping_address_2"]').val(address_2);
            $('input[name="shipping_address_3"]').val(address_3);
            $('input[name="shipping_postcode"]').val(postcode);
            $('input[name="shipping_city"]').val(city);
            $('select[name="shipping_country"]').val(country);
            $('input[name="shipping_phone"]').val(phone);
            $('input[name="shipping_mail"]').val(email);
        }
    });

})(jQuery); // Fully reference jQuery after this point.
